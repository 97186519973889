/*** TOASTR ***/

.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: #4487f6;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #10b981;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: #f59e0b;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #ef4444;
}

/* DROPDOWN */

.dropdown:hover .dropdown-menu {
  display: block;
}